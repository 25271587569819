import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/cms-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Services`}</h2>
    <p><strong parentName="p">{`Interior Clean`}</strong>{` – wipe down and clean side walls, windows, tray tables and seats. Then, remove cushion from seats and stack vertically. Restock briefing cards, sick bags, and magazines. Additionally, clean and sanitize lavatories (restock any consumables: toilet paper, tri fold towels, and soap) and gallery areas then vacuum.`}</p>
    <p><strong parentName="p">{`Exterior Clean`}</strong>{` – Service all lavatories, clean and vacuum out all baggage compartments, and clean and remove bugs from windscreen.`}</p>
    <p><strong parentName="p">{`Exterior Detail`}</strong>{` – remove dirt, bugs and heavily soiled items from the fuselage, nacelles and underbelly. Then finish applying a cleaner wax for added protection and shine.`}</p>
    <p><strong parentName="p">{`Deep Seat Clean`}</strong>{` – clean the seat, seat back and seat back pocket thoroughly, remove or reduce and markings. Remove seat cushions from base and deep clean seat track and base. Clean and remove marks from tray tables front and back.`}</p>
    <p><strong parentName="p">{`Deep Carpet Clean`}</strong>{` – vacuum carpet and then use carpet extractor to extract carpet on all carpeted areas.`}</p>
    <p><strong parentName="p">{`Detailed Flight Deck Cleaning`}</strong>{` – clean and dust instrument panels with parts soft bristle brush. Clean screens with approved cleaning cloth and cleaning solution, clean windows, sun visors, and cup holders/ storage areas. Clean the floor and pedestals and vacuum.`}</p>
    <p><strong parentName="p">{`Disinfection`}</strong>{` – After an interior clean is performed and the cabin is prepared for disinfection, use an electrostatic and an approved disinfectant to spray all surfaces in the aircraft systematically to ensure maximum coverage.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      